import { Typography } from "@mui/material";
import { REQUEST_BUTTON_URL } from "config";
import Button from "elements/Button";
import React from "react";
import { BottomNoteText, BtnWrapper, ContactList, ContactListItem, Container, HeadingTwo, SectionWrapper } from "./style";

const ContactInfo = () => {
  return (
    <SectionWrapper>
      <Container className="align-center">
        <HeadingTwo variant="h2">Contact Info</HeadingTwo>
        <ContactList>
          <ContactListItem>
            <Typography variant="h5">Address</Typography>
            <Typography variant="body1">330 Bay Street #820 <br />Toronto ON M5H 2S8 <br />Canada</Typography>
          </ContactListItem>
          <ContactListItem>
            <Typography variant="h5">Telephone</Typography>
            <Typography variant="body1">(647) 333-2698</Typography>
          </ContactListItem>
          <ContactListItem>
            <Typography variant="h5">Email</Typography>
            <Typography variant="body1">access@sredcapital.com</Typography>
          </ContactListItem>
        </ContactList>
        <BottomNoteText variant="body1">On every dollar recovered the Canadian SRED Capital program contributes 11% to the Canadian economy.</BottomNoteText>
        <BtnWrapper>
          <Button color="primary" onClick={() => window.location.href = REQUEST_BUTTON_URL}>REQUEST A CONSULTATION</Button>
        </BtnWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default ContactInfo;
