import { REQUEST_BUTTON_URL } from "config";
import React from "react";
import { Container, QuotesHolder, QuoteBox, HeadingTwo, SectionWrapper, QuoteCol, BtnWrapper } from "./style";
import SiteLogo from "assets/light_theme/logo.svg";
import Button from "elements/Button";
import { Typography } from "@mui/material";

const CreditInfo = () => {
  return (
    <SectionWrapper>
      <Container>
        <HeadingTwo
          variant="h2"
        >
          By Canadians, For Canadians
        </HeadingTwo>
        <QuotesHolder>
          <QuoteCol>
            <QuoteBox>
              <div className="heading">
                <Typography variant="h4">Jennifer H.</Typography>
                <span className="designation">CTO of DataSphere Inc</span>
              </div>
              <Typography variant="h5">SRED Capital saved us from paperwork</Typography>
              <Typography variant="body1">"Before SRED Capital, we were drowning in paperwork and unsure if we were even eligible. They simplified the whole process and helped us uncover R&D activities we didn't realize qualified. Our claim value doubled!"</Typography>
            </QuoteBox>
          </QuoteCol>
          <QuoteCol>
            <QuoteBox>
              <div className="heading">
                <Typography variant="h4">Dr. Amit Patel</Typography>
                <span className="designation">Founder of NovaBio Solutions</span>
              </div>
              <Typography variant="h5">SRED Capital returned time back to our R&D team</Typography>
              <Typography variant="body1">"As a small biotech startup, we couldn't afford to divert our scientists' time to SRED Capital claims. SRED Capital took that burden off our shoulders, allowing us to focus on our research while still benefiting from the tax credits."</Typography>
            </QuoteBox>
          </QuoteCol>
          <QuoteCol>
          <QuoteBox>
            <div className="heading">
              <Typography variant="h4">Robert C.</Typography>
              <span className="designation">CFO of RobotiX Automation</span>
            </div>
            <Typography variant="h5">SRED Capital saved us from audits</Typography>
            <Typography variant="body1">“We used to live in constant fear of audits. SRED Capital's meticulous approach not only maximized our claim but gave us confidence in its accuracy. When we did face an audit, they guided us through it seamlessly."</Typography>
          </QuoteBox>
          </QuoteCol>
        </QuotesHolder>
        <BtnWrapper>
          <Button color="primary" onClick={() => window.location.href = REQUEST_BUTTON_URL}>Meet With David</Button>
        </BtnWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default CreditInfo;
