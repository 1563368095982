import { REQUEST_BUTTON_URL } from "config";
import React from "react";
import { BtnWrapper, Container, HeadHeading, IconWrap, IntroWrapper, TickList, TickListItem } from "./style";
import Button from "elements/Button";
import { List, ListItem, Typography } from "@mui/material";
import Tick from "assets/light_theme/check.svg";

const Intro = () => {
  return (
    <IntroWrapper>
      <Container>
        <HeadHeading
          variant="h1"
        >
          <strong className="text-highlight">Attention CEOs & CFOs:</strong> Unlock up to 70% of your payroll costs paid last year, this year & every year with SRED Capital
        </HeadHeading>
        <TickList>
          <TickListItem><IconWrap><img src={Tick} width={'20'} height={'20'} /></IconWrap>$0 upfront, pay upon success</TickListItem>
          <TickListItem><IconWrap><img src={Tick} width={'20'} height={'20'} /></IconWrap>98% success rate</TickListItem>
          <TickListItem><IconWrap><img src={Tick} width={'20'} height={'20'} /></IconWrap>20 years in business</TickListItem>
          <TickListItem><IconWrap><img src={Tick} width={'20'} height={'20'} /></IconWrap>Canadian owned & operated</TickListItem>
        </TickList>
        <BtnWrapper>
          <Button color="primary" onClick={() => window.location.href = REQUEST_BUTTON_URL}>REQUEST A CONSULTATION</Button>
        </BtnWrapper>
      </Container>
    </IntroWrapper>
  );
};

export default Intro;
